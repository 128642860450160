import Container from '@/components/Container';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import GreetingTab from '@/page-blocks/about-us/unicef/greetingTab';
import OrganizationTab from '@/page-blocks/about-us/unicef/organizationTab';
import { PageProps, navigate } from 'gatsby';
import React, { ReactElement, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

const tabTitle = [`한국위원회`, `인사말`];
const tab: { [key: number]: ReactElement } = {
  0: <OrganizationTab />,
  1: <GreetingTab />,
};

const tab2 = [
  {
    idx: 0,
    title: `한국위원회`,
    link: `/about-us/unicef/organization/`,
  },
  {
    idx: 1,
    title: `인사말`,
    link: `/about-us/unicef/greeting/`,
  },
];

const PanelContainer = styled.div`
  height: 80px;
  border-bottom: 3px solid #c6c6c6;

  .UiTabPanel {
    & > li {
      width: 50%;
      text-align: center;
    }

    // 인사말 탭 디자인 수정
    li:last-child {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: color 0.15s ease-in-out, border-color 0.15s ease-in-out;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #1cabe2;
        width: 130px;
        height: 46px;
        ${breakpoint('mobile')} {
          width: 100px;
        }
      }
      :hover div {
        background-color: #ffffff;
      }
    }
  }
`;
const TabButton = styled.li<{ active: boolean }>`
  cursor: pointer;
  width: 50%;
  text-align: center;
  display: block;
  font-weight: 700;
  line-height: 80px;
  font-size: 20px;
  height: 80px;
  border-bottom: 3px solid #c6c6c6;
  border-color: ${(props) => (props.active ? `#1cabe2` : `#c6c6c6`)};
  color: ${(props) => (props.active ? `#1cabe2` : `#c6c6c6`)};
  transition: color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  margin: 0;
  &:hover {
    border-color: #1cabe2;
    color: #1cabe2;
  }
`;
const TabContainer = styled.div``;
const SectionPanel = styled.section``;

const Organization: React.FC<PageProps> = ({ location }) => {
  const path = useMemo(() => location.pathname, [location]);
  const targetRef = useRef<HTMLLIElement | null>(null);

  const [activeTab, setActiveTab] = useState(0);

  const handleTabMenu = (id: number, link: string) => {
    navigate(link);
    setActiveTab(id);
  };

  return (
    <LayoutWithTitle
      location={location}
      paddingBtt
      title="조직"
      description="for every child, UNICEF organization"
    >
      <SectionPanel>
        <PanelContainer>
          <Container>
            <ul className="UiTabPanel flex">
              {tab2.map(({ title, link, idx }) => (
                <TabButton
                  ref={(ref) => {
                    if (path === link) {
                      targetRef.current = ref;
                    }
                  }}
                  active={path === link}
                  key={link}
                  onClick={() => handleTabMenu(idx, link)}
                >
                  <div>{title}</div>
                </TabButton>
              ))}
            </ul>
          </Container>
        </PanelContainer>
        <TabContainer>{tab[activeTab]}</TabContainer>
      </SectionPanel>
    </LayoutWithTitle>
  );
};
export default Organization;
